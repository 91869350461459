import React, { ForwardedRef, forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form";
import { MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { LadderProfile } from "@src/store/apis/anbotoApi/types";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

const LADDER_PROFILE: LadderProfile[] = [
  LadderProfile.NEAREST,
  LadderProfile.NEAR,
  LadderProfile.LINEAR,
  LadderProfile.FAR,
  LadderProfile.FARTHEST,
];

export const LADDER_PROFILE_LABEL: Record<LadderProfile, string> = {
  [LadderProfile.NEAREST]: "Nearest",
  [LadderProfile.NEAR]: "Near",
  [LadderProfile.LINEAR]: "Linear",
  [LadderProfile.FAR]: "Far",
  [LadderProfile.FARTHEST]: "Farthest",
};

export const LADDER_PROFILE_DESCRIPTION: Record<LadderProfile, string> = {
  [LadderProfile.NEAREST]: "Places most of the volume near the current market price",
  [LadderProfile.NEAR]: "Places higher volume near the market price with gradual decrease",
  [LadderProfile.LINEAR]: "Distributes volume evenly across all price levels",
  [LadderProfile.FAR]: "Places higher volume away from the market price",
  [LadderProfile.FARTHEST]: "Places most of the volume at the furthest price levels",
};

type LadderProfileFieldProps = ControllerRenderProps & {
  select?: boolean;
  disabled?: boolean;
  labelStyle?: AnbotoTextFieldProps["labelStyle"];
  label?: string;
} & AnbotoTextFieldProps;

export const LadderProfileField = forwardRef((props: LadderProfileFieldProps, ref: ForwardedRef<null>) =>
  props.select ? (
    <AnbotoTextField
      inputRef={ref}
      select
      disabled={props.disabled}
      label={
        <Stack direction="row" gap={1} alignItems="center">
          <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
            {props.label || "Scale Profile"}
          </Typography>
          <Tooltip title="Select how your order will be split across multiple price levels">
            <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
          </Tooltip>
        </Stack>
      }
      size="small"
      fullWidth
      {...props}
    >
      {LADDER_PROFILE.map((p) => (
        <MenuItem key={p} value={p}>
          {LADDER_PROFILE_LABEL[p]}
        </MenuItem>
      ))}
    </AnbotoTextField>
  ) : (
    <AnbotoButtonGroup<LadderProfile>
      defaultValue={props.value}
      view="outlined"
      sx={{ mt: 0, display: "flex" }}
      options={LADDER_PROFILE.map((p) => ({
        label: LADDER_PROFILE_LABEL[p],
        value: p,
      }))}
      onChange={(value: string) => props.onChange(value)}
    />
  )
);

LadderProfileField.displayName = "LadderProfileField";
