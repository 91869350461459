import { memo, useCallback, useRef, useState } from "react";
import { Box, Slider, Stack, styled } from "@mui/material";
import { ComposedChart, Bar, Line, YAxis, ResponsiveContainer } from "recharts";
import _debounce from "lodash/debounce";
import { LadderProfile, OrderSide } from "@src/store/apis/anbotoApi/types";

const profileData = {
  [LadderProfile.NEAREST]: [35, 25, 15, 10, 5, 3, 3, 2, 1, 1],
  [LadderProfile.NEAR]: [25, 20, 15, 12, 10, 7, 5, 3, 2, 1],
  [LadderProfile.LINEAR]: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
  [LadderProfile.FAR]: [1, 2, 3, 5, 7, 10, 12, 15, 20, 25],
  [LadderProfile.FARTHEST]: [1, 1, 2, 3, 3, 5, 10, 15, 25, 35],
};

const preCalculatedChartData = Object.entries(profileData).reduce(
  (acc, [profile, values]) => {
    acc[profile as LadderProfile] = values.reduce(
      (chartAcc, value, index) => {
        const prevCumulative = chartAcc[index - 1]?.cumulative || 0;
        return [
          ...chartAcc,
          {
            name: index + 1,
            value,
            cumulative: prevCumulative + value,
          },
        ];
      },
      [] as Array<{ name: number; value: number; cumulative: number }>
    );
    return acc;
  },
  {} as Record<LadderProfile, Array<{ name: number; value: number; cumulative: number }>>
);

const markByProfile = {
  [LadderProfile.NEAREST]: 0,
  [LadderProfile.NEAR]: 25,
  [LadderProfile.LINEAR]: 50,
  [LadderProfile.FAR]: 75,
  [LadderProfile.FARTHEST]: 100,
};

const profileByValue = Object.entries(markByProfile).reduce(
  (acc, [profile, value]) => {
    acc[value] = profile as LadderProfile;
    return acc;
  },
  {} as Record<number, LadderProfile>
);

const marks = [
  { profile: LadderProfile.NEAREST, value: markByProfile[LadderProfile.NEAREST], label: "Nearest" },
  { profile: LadderProfile.NEAR, value: markByProfile[LadderProfile.NEAR], label: "Near" },
  { profile: LadderProfile.LINEAR, value: markByProfile[LadderProfile.LINEAR], label: "Linear" },
  { profile: LadderProfile.FAR, value: markByProfile[LadderProfile.FAR], label: "Far" },
  { profile: LadderProfile.FARTHEST, value: markByProfile[LadderProfile.FARTHEST], label: "Farthest" },
];

export const LadderProfileSlider = memo(
  ({
    side,
    value,
    onChange,
    height,
  }: {
    side: OrderSide;
    value: LadderProfile;
    onChange?: (value: LadderProfile) => void;
    height?: number;
  }) => {
    const sliderValueRef = useRef(markByProfile[value]);
    const [sliderValue, setSliderValue] = useState(markByProfile[value]);

    const chartData = preCalculatedChartData[profileByValue[sliderValue]];

    const udpateCallback = () => {
      console.log(profileByValue[sliderValueRef.current]);
      onChange(profileByValue[sliderValueRef.current]);
    };

    const debouncedOnChange = useCallback(_debounce(udpateCallback, 1000), []);

    const handleSliderChange = (_: Event, value: number) => {
      setSliderValue(value);
      sliderValueRef.current = value;
      debouncedOnChange();
    };

    const color = side === OrderSide.BUY ? "#009687" : "#cf3844";

    return (
      <Stack width="100%" gap={1}>
        {onChange && (
          <Stack direction="row" alignItems="center" position="relative" px={1}>
            <StyledSlider value={sliderValue} onChange={handleSliderChange} marks={marks} step={null} />
          </Stack>
        )}
        <Box position="relative" mt={1}>
          <ResponsiveContainer width="100%" height={height || 40}>
            <ComposedChart data={chartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <Bar dataKey="value" fill={color} yAxisId="bar" opacity={0.4} />
              <Line type="monotone" dataKey="cumulative" stroke={color} dot={false} yAxisId="line" strokeWidth={2} />
              <YAxis yAxisId="bar" orientation="left" hide domain={[0, 35]} />
              <YAxis yAxisId="line" orientation="right" hide domain={[0, 100]} />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    );
  }
);

LadderProfileSlider.displayName = "LadderProfileSlider";

const StyledSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-markLabel": {
    fontSize: 13,
    color: theme.palette.text.disabled,
    userSelect: "none",
    [`&[data-index="0"]`]: {
      transform: "none !important",
    },
    [`&[data-index="4"]`]: {
      transform: "translateX(-90%) !important",
    },
  },
  "& .MuiSlider-thumb": {
    height: 10,
    width: 10,
    transition: "none",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none !important",
  },
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "transparent",
    transition: "none",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#3B4043",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#3B4043",
    height: 8,
    width: 8,
    borderRadius: "50%",
  },
}));
