import { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form";
import { Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

const TOOLTIPS = {
  SCALE_START_PRICE:
    "The starting price of the scale order. The price threshold where the scaling mechanism begins executing orders.",
  SCALE_END_PRICE:
    "The ending price of the scale order. The price threshold where the scaling mechanism stops executing orders.",
};

type LadderPriceFieldProps = ControllerRenderProps & AnbotoTextFieldProps & { type: "start" | "end" };

export const LadderPriceField = forwardRef((props: LadderPriceFieldProps, ref) => {
  const label = props.label || (props.type === "start" ? "Start scale price" : "End scale price");
  const tooltip = props.type === "start" ? TOOLTIPS.SCALE_START_PRICE : TOOLTIPS.SCALE_END_PRICE;

  return (
    <AnbotoTextField
      {...props}
      inputRef={ref}
      variant="outlined"
      size="small"
      label={
        <Stack direction="row" gap={1} alignItems="center" position="relative">
          <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
            {label}
          </Typography>
          <Tooltip title={tooltip}>
            <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
          </Tooltip>
        </Stack>
      }
      fullWidth
      type="number"
    />
  );
});

LadderPriceField.displayName = "LadderPriceField";
